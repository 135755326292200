<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card
      :loading="loading"
      :disabled="loading"
      ref="form"
      elevation="2"
      class="pa-5 mt-5"
    >
      <v-card-title>
        カテゴリーの編集
      </v-card-title>

      <div v-if="categories">
        <v-card-text>
          <v-text-field
            ref="name"
            v-model="categories.name"
            :rules="rules.name"
            label="カテゴリー名"
            required
          ></v-text-field>
        </v-card-text>

        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text :to="{ name: 'Categories' }">
            キャンセル
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            更新する
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'カテゴリー管理',
          disabled: false,
          to: { name: 'Categories' },
          exact: true
        },
        {
          text: 'カテゴリーの編集',
          disabled: true,
          to: { name: '' },
          exact: true
        }
      ],
      categories: null,
      rules: {
        name: [v => !!v || 'カテゴリー名は必須です。']
      },
      loading: false
    }
  },
  methods: {
    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) {
        this.updateCategory()
      }
    },
    getCategory(id) {
      this.loading = true
      api
        .get('categories/' + id)
        .then(response => {
          this.loading = false
          this.categories = response.data.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    updateCategory() {
      this.loading = true
      api
        .update('categories/' + this.categories.id, this.form)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: response.data.message
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({ name: 'Categories' })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mounted() {
    this.getCategory(this.$route.params.id)
  },

  computed: {
    form() {
      return {
        name: this.categories.name
      }
    }
  }
}
</script>
